.is-flex { display: flex; min-width: 0;}
.is-column { flex-direction: column; }
.is-row { flex-direction: row; }

.is-justify-center { justify-content: center; }
.is-justify-start { justify-content: flex-start; }
.is-justify-end { justify-content: flex-end; }
.is-justify-space-between { justify-content: space-between; }
.is-justify-space-evenly { justify-content: space-evenly; }
.is-justify-space-around { justify-content: space-around; }
// @deprecated
.is-space-between { justify-content: space-between; }

.is-align-center { align-items: center; }
.is-align-start { align-items: flex-start; }
.is-align-end { align-items: flex-end; }

.is-align-self-center { align-self: center; }
.is-align-self-start { align-self: flex-start; }
.is-align-self-end { align-self: flex-end; }
.is-align-self-stretch { align-self: stretch; }

.has-dividers > :not(:last-child) {
  border-bottom: solid 1px var(--silver);
}

.is-grid { display: grid; }

.is-relative { position: relative; }

.is-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gap-2 { gap: 2px; };
.gap-4 { gap: 4px; };
.gap-8 { gap: 8px; };
.gap-12 { gap: 12px; };
.gap-16 { gap: 16px; };
.gap-20 { gap: 20px; };
.gap-24 { gap: 24px; };
.gap-32 { gap: 32px; };
.gap-40 { gap: 40px; };

.radius-6 { border-radius: 6px; }
.radius-8 { border-radius: 8px; }
.radius-10 { border-radius: 10px; }
.radius-12 { border-radius: 12px; }
.radius-14 { border-radius: 14px; }
.radius-16 { border-radius: 16px; }

.flex-wrap { flex-wrap: wrap; }
